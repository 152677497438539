<template>
    <div class="main">
        <div class="top_box">
            <span class="toptitle">整体情况</span>
            <div>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin: 20px 0 8px 50px;">
                    <el-col :span="6">
                        <div class="title">辖区学校</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;&nbsp;幼儿园（所） </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;小学（所）</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;初中（所）</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;高中（所）</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin-left: 50px;">
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.schoolnum }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.yey }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.xx }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.cz }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.gz }}</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin: 35px 0 8px 50px;">
                    <el-col :span="6">
                        <div class="title">年级</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">班级</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">总学生数</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;男生（人）</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="title">&nbsp;女生（人）</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin-left: 50px;">
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.nj }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.bj }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.num }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.num1 }}</div>
                    </el-col>
                    <el-col :span="6">
                        <div class="numdata">{{ schoolinfo.num2 }}</div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="middle_box">
            <div class="middletitle">
                <div class="titleword">本期筛查情况</div>
                <div>
                    <el-button round class="btn" @click="examine">查看数据展示</el-button>
                    <el-button round class="btn" @click="jumpExInfo" :disabled="disabled">下载视力报告</el-button>
                    <el-select v-model="screening" class="inputsel" placeholder="暂无筛查数据" filterable
                        @change="changesel(screening)" ref="selinfo">
                        <el-option v-for="item in options" :key="item.index" :label="item.cname"
                            :value="item.cname + ',' + item.ctime">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin: 30px 0 8px 0;">
                    <el-col :span="5">
                        <div class="title">筛查完成学校</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">筛查完成率</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">已筛查人数</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">未筛查人数</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">筛查完成率</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">领取档案家长数</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">家长领取率</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between">
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.anum }}</div>
                        <!-- <div class="numdata">54</div> -->
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">&nbsp;&nbsp;&nbsp;{{ conditioninfo.percentage }}%</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.examinenum }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.notnum }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">&nbsp;&nbsp;&nbsp;{{ conditioninfo.examinerate }}%</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num8 }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">&nbsp;&nbsp;&nbsp;{{ conditioninfo.receive == '' ? '0' : conditioninfo.receive }}%</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between" style="margin: 30px 0 8px 0;">
                    <el-col :span="5">
                        <div class="title">正常视力</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">视力不良</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">视力不良率</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">屈光正常</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">筛查性近视</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="title">筛查性近视率</div>
                    </el-col>
                </el-row>
                <el-row :gutter="0" type="flex" justify="space-between">
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num3 }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num4 }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.entirety }}%</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num9 }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num5 }}</div>
                    </el-col>
                    <el-col :span="5">
                        <div class="numdata">{{ conditioninfo.num6 == 'NaN' ? '0' : conditioninfo.num6 }}%</div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="bom_box">
            <span class="toptitle">各学校筛查进度</span>
            <div id="barChart"></div>
        </div>
    </div>
</template>

<script>
import { pileupBarChart } from './echart.js';
export default {

    data() {
        return {
            schoolinfo: {},
            conditioninfo: {},
            screening: '',
            options: [],
            cname: '',
            axisLabel: { fontSize: 13 },
            axisLabel1: {
                fontSize: 13,
                interval: 0,
                formatter: function (value) {
                    return value.split("").join("\n");
                }
            },
            disabled: false,
        };
    },

    mounted() {
        this.schoolentirety()
        this.screeningsel()
    },

    methods: {
        jumpExInfo() {
            const route = this.$router.resolve({
                path: '/exInfo',
                query: {
                    cname: this.cname
                }
            })
            window.open(route.href, '_blank')
        },
        schoolentirety() {
            this.$api.managehome.schoolentirety({
            }).then(res => {
                console.log(res)
                if(res.status == 1) {
                    this.schoolinfo = res.obj
                }
            })
        },
        screeningsel() {
            this.$api.managehome.screeningsel({
            }).then(res => {
                console.log(res)
                this.options = res.obj
                if (this.options.length != 0) {
                    this.screening = this.options[0].cname
                    this.cname = this.options[0].cname
                    this.performance()
                    this.screeningAnalyst()
                } else {
                    this.disabled = true
                    pileupBarChart('barChart', [], [0], [0], this.axisLabel, '13%')
                    this.conditioninfo.anum = 0
                    this.conditioninfo.percentage = 0
                    this.conditioninfo.examinenum = 0
                    this.conditioninfo.notnum = 0
                    this.conditioninfo.examinerate = 0
                    this.conditioninfo.num3 = 0
                    this.conditioninfo.num4 = 0
                    this.conditioninfo.entirety = 0
                }
            })
        },
        performance() {
            this.$api.managehome.performance({
                cname: this.cname
            }).then(res => {
                // console.log(res)
                if (res.status == 1) {
                    if (res.obj.title.length > 7) {
                        pileupBarChart('barChart', res.obj.title, res.obj.data2, res.obj.data1, this.axisLabel1, '35%')
                    } else {
                        pileupBarChart('barChart', res.obj.title, res.obj.data2, res.obj.data1, this.axisLabel, '13%')
                    }
                }
            })
        },
        screeningAnalyst() {
            this.$api.managehome.screeningAnalyst({
                cname: this.cname
            }).then(res => {
                console.log(res)
                this.conditioninfo = res.obj
            })
        },
        changesel(row) {
            let myChart = this.$echarts.getInstanceByDom(document.getElementById("barChart"))
            if (myChart != null && myChart != "" && myChart != undefined) {
                myChart.dispose();
            }
            this.cname = row.split(',')[0]
            // console.log(this.cname)
            this.screeningAnalyst()
            this.performance()
        },
        examine() {
            const route = this.$router.resolve({
                path: '/bl',
                query: {
                    cname: this.cname
                }
            })
            window.open(route.href, '_blank')
        },
    },
};
</script>

<style scoped>
.main {
    width: 100%;
    /* height: 100%; */
    padding: 20px 0;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top_box {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    height: 260px;
    width: 98%;
    padding-top: 8px;
}

.toptitle {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    margin: 22px;
}

.title {
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
}

.numdata {
    color: rgba(0, 0, 0, 1);
    font-size: 40px;
    text-align: center;
}

.middle_box {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    height: auto;
    width: 98%;
    margin-top: 15px;
    padding-bottom: 35px;
}

.middletitle {
    margin: 10px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleword {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    /* line-height: 18px; */
}

.btn {
    /* background-color: rgba(255, 255, 255, 1); */
    border-radius: 20px;
    height: 30px;
    /* border: 1px solid rgba(151, 151, 151, 1); */
    width: 130px;
    /* color: rgba(0, 0, 0, 1); */
    font-size: 14px;
}

.el-button.is-round {
    padding: 0;
}

/deep/.el-input--suffix .el-input__inner {
    margin-left: 10px;
    background-color: #d6d6d6;
    border-radius: 20px;
    width: 200px;
    height: 30px;
    color: #999;
}

/deep/.el-select .el-input .el-select__caret {
    color: #999;
    margin-top: 0;
    width: 20px;
    height: 35px;
}

.bom_box {
    margin-top: 15px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    height: 650px;
    width: 98%;
    padding-top: 10px;
}

#barChart {
    width: 100%;
    height: calc(100% - 10px);
    margin-top: 10px;
}
/deep/.el-input--suffix .el-input__inner{
    width: 220px;
}
</style>